

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { callApi, detectClientOS, updateDownloadReport, getRootDomain } from "@/api/api"
import { getChannelNameScriptSRC } from '@/config/channels'

export default {
    name: 'Home',
    components: {

    },

    setup() {

        const store = useStore()
        const router = useRouter()
        const auth = computed(() => store.state.authenticated)
        const authUser = computed(() => store.state.authUser)
        //const langs = computed(() => store.state.translation.langs);
        const locale = computed(() => store.state.translation.locale)
        const downloads = computed(() => store.state.channel.downloads)


        const data = reactive({
            endpoint: '/dashboard',
            records: [],
            loading: false,
            clientOS: '', 
            clientType: '',
            clientOSImage: '',
            clientOSLink: '',
        })

        const scrollTo = (sectionId) => {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.error(`No section found with the id ${sectionId}`);
            }
        }


        const downloadFile = () => {
  

            console.log("detectClientOS===",data.clientOS ,data.clientType,  data.clientOSLink)
            //window.open( 'http://'+window.location.host+data.clientOSLink, '_blank')
            // Create a link element
            const link = document.createElement('a');
            link.href = data.clientOSLink;
            link.style.display = 'none'; // Ensure it's hidden 
            // Use a unique ID to avoid conflicts
            link.id = 'auto-download-link'; // Use a unique ID to avoid conflicts  
            // Append to the body
            document.body.appendChild(link);
            // Trigger the download
            link.click();
            // Remove the link
            document.body.removeChild(link); 

            // save to download report 
            updateDownloadReport(window.location.href, data.clientType , getRootDomain(window.location.host));

        }




        onMounted(async () => {
            console.log(`new version 2 of website===================`);
            data.loading = true
            //let lang = authUser.value.preferred_language ? authUser.value.preferred_language : "en";
            //store.dispatch(`translation/getLangs`, { userLang: lang });
            //let resData = await callApi(data.endpoint, 'GET')
            //data.records = resData.data


            await store.dispatch(`channel/setChannel`, {});
            console.log("Set Channel Dispatched=====================", downloads)
 
            await detectClientOS(data, locale,downloads).then((result) => {
                data.clientOSLink = result.clientOSLink;
                data.clientOSImage = result.clientOSImage;
                data.clientOS = result.clientOS;
                data.clientType = result.clientType;
            })



            // Dynamically inject the script when this component is mounted
            var _hmt = _hmt || [];
            (function () { 
                var hm = document.createElement("script"); 
                hm.src = getChannelNameScriptSRC(); 
                var s = document.getElementsByTagName("script")[0];
                s.parentNode?.insertBefore(hm, s);
            })();




            data.loading = false
        })




        return {
            auth,
            data,
            locale,
            authUser,
            scrollTo,
            downloadFile,
            detectClientOS
        }
    }
}
