

import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex' 
import {useRouter, useRoute} from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import { callApi,detectClientOS , updateDownloadReport } from "@/api/api"

export default {
    name: 'windows',
    components: {
        Breadcrumb
    },
    
    setup() {

        const store = useStore()  
        const locale = computed(() => store.state.translation.locale) 
        const downloads = computed(() => store.state.channel.downloads)
        const route = useRoute()
        

        const data = reactive({ 
            loading: false,  
            hover:false,
            page:1,
            channel:'',
            clientOS: '',
            clientType: '',
            clientOSImage: '',
            clientOSLink : '',
            d:'n',// auto download
            images:[
                '/images/tutorials/windows/1.svg',
                '/images/tutorials/windows/2.svg',
                '/images/tutorials/windows/3.svg',
                '/images/tutorials/windows/4.svg',
                '/images/tutorials/windows/5.svg',
                '/images/tutorials/windows/6.svg',
                '/images/tutorials/windows/7.svg',
                '/images/tutorials/windows/8.svg',
                '/images/tutorials/windows/9.svg', 
            ]
        })


        onMounted(async () => {

            data.loading = true

            data.page = parseInt(route.query.page as string) || 1
            data.channel =   route.query.channel && typeof(route.query.channel)!='undefined' ? route.query.channel as string : '';
            data.d = route.query?.d as string 
            //console.log("onMounted windows page is 1====",route.query) 
            
            console.log("Set Channel Dispatched=====================")
            await store.dispatch(`channel/setChannel`, {});

            if (data.d == 'y')
            detectClientOS(data, locale,downloads, "windows").then((result) => {
            data.clientOSLink = result.clientOSLink;
            data.clientOSImage = result.clientOSImage;
            data.clientOS = result.clientOS;
            data.clientType = result.clientType;
                console.log("detectClientOS===", data.clientOSLink)
              
                    const link = document.createElement('a');
                   link.href =  data.clientOSLink;  
                   link.style.display = 'none'; // Ensure it's hidden 
                   // Use a unique ID to avoid conflicts
                   link.id = 'auto-download-link'; // Use a unique ID to avoid conflicts  
  
                     link.target="_self"

                    // Append to the body
                    document.body.appendChild(link); 
                    // Trigger the download
                    link.click(); 
                    // Remove the link
                    document.body.removeChild(link);
             
                

                // save to download report 
                updateDownloadReport(window.location.href,'windows',data.channel);
                   
            });
            data.loading = false
        })

        
        const handleMouseOver=()=>{
         console.log("handleMouseOver===")
         data.hover = true
        }

        const handleMouseLeave=()=>{
            console.log("handleMouseLeave===")
            data.hover = false
       }
 

        const openImageInNewTab=(fullImageSrc: string)=>{
          window.open(fullImageSrc, '_blank');
        }
 

        return { 
            data,
            locale,
            openImageInNewTab ,
            handleMouseOver,
            handleMouseLeave,
        }
    }
}
