
import { apiHost } from '@/config/config'
import { useStore } from '@/store'    


const store = useStore()  


export async function callApi(endpoint: string, method: string) { 

    let headers;
    if ((store.state.authUser as any)?.accessToken) {
        headers = {
            'Authorization': (store.state.authUser as any)?.accessToken??'',
            'Content-type': 'application/json; charset=UTF-8',
        };
    } else {
        headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
    }
    return await fetch(apiHost + endpoint, {
        method: method,
        headers,
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
        if (!response.ok) {
            //401 == Unauthorized
            if (response.status == 401) {
                resData.notLogin = true
                await store.dispatch("setAuth", false)
                await store.dispatch("setAuthUser", null) 
            }
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })

}

export async function callPostApi(endpoint: string, method: string, _body: any) {

    // console.log("callPostApi",endpoint)
    // let ipDetail = 'start'
    // await fetch('https://api.ipify.org?format=json')
    // .then(x => x.json())
    // .then(({ ip }) => {
    //     ipDetail = ip
    //     console.log("client ip detail in veu.js",ip)
    // }).catch(error => {
    //     console.log("client ip err",error)
    // })
    // console.log("callPostApi 2")

    let headers;
    if ((store.state.authUser as any)?.accessToken) {
        headers = {
            'Authorization': (store.state.authUser as any)?.accessToken??'',
            'Content-type': 'application/json; charset=UTF-8',
        };
    } else {
        headers = {
            "Content-type": "application/json; charset=UTF-8",
        };
    }
 

    return await fetch(apiHost + endpoint, {
        method: method,
        headers,
        body: _body
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
       // resData.ip = ipDetail
        if (!response.ok) {
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })
}

export async function uploadFile(_body: any) {
    return await fetch(apiHost + '/upload', {
        method: 'PUT',
        headers: {
            'Authorization': (store.state.authUser as any).accessToken,
        },
        body: _body
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
        if (!response.ok) {
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })
}

export async function getLanguageList() {
    const resData = await callApi("/language", 'GET')
    return resData.data
}

export async function getPaymentMethodsList() {
    const resData = await callApi('/paymentmethods', 'GET')
    return resData.data
}

export async function getCurrencyList() {
    const resData = await callApi("/currency", 'GET')
    return resData.data
}
export async function getDomainsList() {
    const resData = await callApi("/domains?active=1", 'GET')
    return resData.data
}

export async function getSystemPlansList(isList=1) {
    const resData = await callApi(`/systemplan?isList=${isList}`, 'GET')
    return resData.data
}

export async function getCoinsPlansList(isList=1) {
    const resData = await callApi(`/coinsplans?isList=${isList}`, 'GET')
    return resData.data
}

export async function getSystemSpeedsList() {
    const resData = await callApi("/speeds", 'GET')
    return resData.data
}

export async function getSystemBandwidthsList() {
    const resData = await callApi("/bandwidths", 'GET')
    return resData.data
}

export async function getCategoriesList() {
    const resData = await callApi("/messages/categories-lists/all", 'GET')
    return resData.data
}

export async function getPlansCategoriesList() {
    const resData = await callApi("/systemplan/categories-lists/all", 'GET')
    return resData.data
}

export async function getBatchList() {
    const resData = await callApi("/batch", 'GET')
    return resData.data
}

export async function getGroupsList() {
    const resData = await callApi("/groups", 'GET')
    return resData.data
}

export async function getChannelsList() {
    const resData = await callApi("/channels", 'GET')
    return resData.data
}



export async function getServersAreasList() {
    const resData = await callApi("/servers-areas", 'GET')
    return resData.data
}

export async function getServersTypesList() {
    const resData = await callApi("/servers-types", 'GET')
    return resData.data
}

export async function getServersListsList() {
    const resData = await callApi("/servers-lists", 'GET')
    return resData.data
}

export async function getClientsList() {
    const resData = await callApi("/clients", 'GET')
    return resData.data
}

export async function getRoles() {
    const resData = await callApi("/roles", 'GET')
    return resData.data
}

export async function getCategories(lang_code = 'CN', perPage = 500) {
    const resData = await callApi("/category?lang=" + lang_code + "&perPage=" + perPage, 'GET')
    return resData.data
}
export async function getCountriesList() {
    const resData = await callApi("/countries", 'GET')
    return resData.data
}
export async function getAdsourceList() {
    const resData = await callApi("/ad-source", 'GET')
    return resData.data
}
export async function getAdsourceCodeList() {
    const resData = await callApi("/ad-source-code", 'GET')
    return resData.data
}
export async function getAdminUserList() {
    const resData = await callApi("/admin-users", 'GET')
    return resData.data
}
export async function getUserList() {
    const resData = await callApi("/user-list", 'GET')
    return resData.data
}

export async function getUserAvailableCoins(userId) {
    const resData = await callApi("/coins/user/"+userId, 'GET')
    return resData.data
}

export async function getPointTypeSettings(lang_code = 'CN', perPage = 500) {
    const resData = await callApi("/points?lang=" + lang_code + "&perPage=" + perPage,'GET')
    return resData.data
}

/* function getRootDomain(host) {
    let domainParts = host.split('.');
    const partsLength = domainParts?.length;

    

    // Check if the domain is an IP address
    if ((partsLength === 4 && domainParts.every(part => !isNaN(part))) || host.indexOf("local") > -1 ) {
        return host.replace(/\/$/, '')
    }

    // Extract the root domain (e.g., example.com from sub.example.com)
    let rootDomain = domainParts.slice(partsLength - 2).join('.');

    // Handle cases like co.uk
    if (domainParts[partsLength - 2].length === 2 && domainParts[partsLength - 1].length === 2) {
        rootDomain = domainParts.slice(partsLength - 3).join('.');
    }


    domainParts = rootDomain.split('/');
    //console.log("getRootDomain =========",domainParts[0]) 
    return domainParts[0]; // Remove trailing slash if it exists
} */

    export   function getRootDomain(url) {
        try {
          // Check if it's an IP address or a plain string (localhost, etc.)
          if (/^(?:\d{1,3}\.){3}\d{1,3}$/.test(url) || /^[a-zA-Z0-9.-]+$/.test(url)) {
            return url;
          }
      
          // Create a URL object to extract the hostname
          let hostname = new URL(url).hostname;
      
          // Split the hostname by dots
          const parts = hostname.split('.');
      
          // If it's localhost or a single-part domain, return it as is
          if (parts.length === 1) {
            return hostname;
          }
      
          // Special handling for co.uk, gov.uk, etc.
          const tlds = ['co.uk', 'gov.uk', 'ac.uk', 'edu.au'];  // Add other multi-part TLDs if needed
          const lastTwoParts = parts.slice(-2).join('.');
          if (tlds.includes(lastTwoParts)) {
            return parts.slice(-3).join('.');
          }
      
          // Otherwise, return the last two parts (root domain)
          return parts.slice(-2).join('.');
        } catch (e) {
          // If an invalid URL is passed, return it as is (localhost, etc.)
          return url;
        }
      }


export async  function updateDownloadReport(url,client,channel='') {

    let clientIp = ''
    await fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
        clientIp = ip
        //console.log("client ip detail in veu.js",ip)
    }).catch(error => {
        console.log("client ip err",error)
    })

    let referrer = ''
    try{ 
        referrer = await getRootDomain(document.referrer)
    }
    catch(err){
        referrer = ''
    }
    
    const Channel = await detectChannel(channel)


    const _body = {
        ip : clientIp,
        action_id  : Math.floor(Math.random() * 26) + Date.now(),
        channel   : Channel,
        referrer  : referrer,
        device_type   : client, // download type : windows or android or mac .........
        url   : url,
        session: 0,//'user_id'
        // back to here to handle user_id if exist
    }

    console.log("updateDownloadReport==========",_body) 

     await callPostApi("/software-download-logs?client_ip="+clientIp,'POST',JSON.stringify(_body)).then((result)=>{
         if(result.isSuccess){
                console.log({text: result.message, type:'success'}) 
            }else{
                console.log({text: result.message, type:'error'}) 
            }
     }).catch((err)=>{
        console.log({text: err.message, type:'error'})
     }) 
           
}

export async  function saveVisitor(Channel='',isHomePage=false) {

    let clientIp = ''
    await fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
        clientIp = ip
        //console.log("client ip detail in veu.js",ip)
    }).catch(error => {
        console.log("client ip err",error)
    }) 
    console.log("visitor Channel==========",Channel)  
    const userAgent = window.navigator.userAgent; 

    const channel = await detectChannel(Channel)

    let referrer = ''
    try{ 
        referrer = await getRootDomain(document.referrer)
    }
    catch(err){
        referrer = ''
    }

    const _body = {
        ip : clientIp, 
        channel   : channel,
        referrer : referrer ,
        device_info   : userAgent,  
        url:window.location.href,
        isHomePage:isHomePage
    } 
    console.log("visitor _body==========",_body) 

     await callPostApi("/visitor",'POST',JSON.stringify(_body)).then((result)=>{
         if(result.isSuccess){
                console.log({text: result.message, type:'success'}) 
            }else{
                console.log({text: result.message, type:'error'}) 
            }
     }).catch((err)=>{
        console.log({text: err.message, type:'error2'})
     }) 
           
}


export function detectChannel(channel=''){

     // channel from query
     if (channel && typeof channel !='undefined' && typeof channel =='string' )
         return channel;

     // Get the referrer URL
     const referrer = document.referrer;

     console.log("Referrer =========",referrer)
  
   const getReferrerSource = (referrer) => {
    try{
       if (!referrer) {
         return getRootDomain(window.location.host);
         //return window.location.host;
       } else if (referrer.includes('google.com')) {
         return 'google.com';
       } else if (referrer.includes('facebook.com')) {
         return 'facebook.com';
       } else if (referrer.includes('youtube.com')) {
         return 'youtube.com';
       }
       else
       {
         return getRootDomain(window.location.host);
         //return getRootDomain(referrer)
       }
    }catch(err){
        console.log("Referrer err=========",err)
        return getRootDomain(window.location.host)
     }
     };
  
     // Function to categorize the referrer
     
 
     // Determine the referrer source
     return getReferrerSource(referrer);
      
 
}


export async  function detectClientOS(data,locale,downloads,OS="") {
    const downloadWindowsClientLink = downloads.value.downloadWindowsClientLink as any ?? '';
    const downloadAndroidClientLink = downloads.value.downloadAndroidClientLink as any ?? '' ;
    const downloadIosClientLink =     downloads.value.downloadIosClientLink as any ?? '' ;
    const downloadLinuxClientLink =     downloads.value.downloadLinuxClientLink as any ?? '' ;
    const userAgent = window.navigator.userAgent; 
    const path="./images/clients/"
    const protocol = window.location.protocol;
    const host = window.location.host;
    const mainPath = protocol + "//" + host;  
    console.log("userAgent==========",userAgent,mainPath)
    console.log("downloadWindowsClientLink==========",downloadWindowsClientLink)
    console.log("downloadAndroidClientLink==========",downloadAndroidClientLink)
    console.log("downloadIosClientLink==========",downloadIosClientLink)
    //alert(userAgent)
    if (OS)
        {
            if (OS=="ios")          {data.clientOS = locale.value.client_ios; data.clientOSImage    = path+'ios48x48.svg'     ;data.clientOSLink=downloadIosClientLink}else 
            if (OS=="android")         {data.clientOS = locale.value.client_android; data.clientOSImage= path+'android48x48.svg' ;data.clientOSLink=downloadAndroidClientLink }else 
        //if (OS=="linux"   || /Linux/.test(userAgent))           {data.clientOS = locale.value.client_linux; data.clientOSImage  = path+'windows48x48.svg' ;data.clientOSLink=downloadLinuxClientLink}else
        //if (OS=="windows" || /Windows NT 10.0/.test(userAgent)) {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=mainPath+'/downloads/jcjsq_live_24.10.4.3.exe'}else 
            if (OS=="windows") {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="windows")  {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (OS=="mac")      {data.clientOS = locale.value.client_mac; data.clientOSImage    = path+'mac48x48.svg'     ;data.clientOSLink=downloadIosClientLink}else 
                                {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}
                                
                                data.clientType = OS;
        }
        else
        {
            if (/iPhone/.test(userAgent) || /iPad/.test(userAgent))          {data.clientType ="ios";data.clientOS = locale.value.client_ios; data.clientOSImage    = path+'ios48x48.svg'     ;data.clientOSLink=downloadIosClientLink}else 
            if (/Android/.test(userAgent))         {data.clientType ="android";data.clientOS = locale.value.client_android; data.clientOSImage= path+'android48x48.svg' ;data.clientOSLink=downloadAndroidClientLink }else 
            //if (OS=="linux"   || /Linux/.test(userAgent))           {data.clientOS = locale.value.client_linux; data.clientOSImage  = path+'windows48x48.svg' ;data.clientOSLink=downloadLinuxClientLink}else
            //if (OS=="windows" || /Windows NT 10.0/.test(userAgent)) {data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=mainPath+'/downloads/jcjsq_live_24.10.4.3.exe'}else 
            if (/Windows NT 10.0/.test(userAgent)) {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 6.3/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 6.2/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 6.1/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 6.0/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 5.1/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Windows NT 5.0/.test(userAgent))  {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}else 
            if (/Mac/.test(userAgent))             {data.clientType ="mac";data.clientOS = locale.value.client_mac; data.clientOSImage    = path+'mac48x48.svg'     ;data.clientOSLink=downloadIosClientLink}else 
                                                   {data.clientType ="windows";data.clientOS = locale.value.client_windows; data.clientOSImage= path+'windows48x48.svg' ;data.clientOSLink=downloadWindowsClientLink}
          
        }
    

    console.log("detectClientOS data.clientOSLink==========",data.clientOSLink)
    return data;
                                        
    }